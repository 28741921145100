
import React, { Component } from 'react';
import './App.css';
import './assets/css/js_composer.min.css';
import Routes from './Routes';


class App extends Component {
	render() {
		return (
			<Routes />
		);
	}
}

export default App;