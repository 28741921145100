import React from "react";
import Header from "./Header";
import { PopupButton } from "react-calendly";
import SEO_page_animated_image from '../images/animation_640.gif'
import AwardSec from '../container/AwardSec';
import check from '../images/Check.svg';
import { HashLink as Link } from 'react-router-hash-link';
import Law_Firm from '../images/Law_Firm.png';
import SEO_FIRM from '../images/SEO_FIRM.png';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Analysis from '../images/analysis.svg';
import Strategy from '../images/Strategy.svg';
import Implementation from '../images/Implementation.svg';
import Dollar from '../images/Dollar.svg';
import analysis_digital_marketing from '../images/analysis-digital-marketing.png';
import strategy_planning from '../images/strategy_planning.png';
import employee_implementing_business_idea from '../images/employee_implementing_business_idea.png';
import seo_Plan from '../images/seo_Plan.png';
import ReviewSec from '../container/ReviewSec';
import Footer from './Footer';
import { PostCate } from '../json/PostCate';
import Letstalk from '../images/Letstalk.png';
import FormPopup from '../container/FormPopup';
import OwlCarousel from 'react-owl-carousel2';
import Page_Optimization from '../images/Page_Optimization.png';
import Off_Page_Optimization from '../images/Off_Page_Optimization.png';
import Keyword_research from '../images/Keyword_Research.png';
import Technical_Seo from '../images/Technical_Seo.png';
import Local_Seo from '../images/Local_Seo.png';
import Firm_SEO from '../images/Firm_SEO.png';
import Content_Marketing from '../images/Content_Marketing.png';
import Swal from "sweetalert2";
import { TailSpin } from 'react-loader-spinner'
import axios from 'axios';
import Popup from 'reactjs-popup';
import { FaUser, FaEnvelope, FaPhone, FaBuilding } from 'react-icons/fa';
import { MdMessage, FaPhoneAlt } from 'react-icons/all';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Helmet } from "react-helmet";

class SEO_Law_firm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      number: "",
      Company: "",
      message: "",
      Loading: true,
      classadd: false,
      servicesJson: [],
      appjson: [],
      Questionjson: [],
      CommerceJson: [],
      Loading: true,
    }
  }

  componentDidMount() {
    PostCate.getWoocommerce().then((wooCommerce, err) => {
      if (!err) {
        this.setState({
          CommerceJson: wooCommerce,
          Loading: false,
        });
      }
    });
  }

  showAlert = () => {
    Swal.fire({
      title: "Thank you!",
      text: `We received your application for ${this.state.Developer_Position}. We appreciate you taking the time to apply.`,
      icon: "success",
      confirmButtonText: "OK",
    }).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 2000)
    });
  }
  showErrorAlert = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: '<a href="">Why do I have this issue?</a>'
    }).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 1000)
    })
  }

  showLoading = () => {
    if (this.state.Loading) {
      return (
        <div className="loader">
          <TailSpin
            color="#00BFFF"
            height={100}
            width={100}
            timeout={3000} //3 secs
          />
        </div>
      )
    }
  }


  submitForm = e => {

    if (this.state.name === '' || this.state.email === '' || this.state.number === '') {
      alert('Please Fill Required All Fields');
    }
    else {
      this.showLoading();
      let formData = new FormData()
      formData.set("FullName", this.state.name)
      formData.set("Email", this.state.email)
      formData.set("Mob_Number", this.state.number)
      formData.set("My_Company", this.state.Company)
      formData.set("your_message", this.state.message)
      axios.post('https://iquincesoft.com/iqsandbox/wp-json/contact-form-7/v1/contact-forms/2174/feedback', formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
        .then(res => {
          if (res.data.status === "mail_sent") {
            this.setState({ Loading: false })
            this.showAlert();
            this.setState({
              name: "",
              email: "",
              number: "",
              Company: "",
              message: "",
            })
          }
        }).catch(err => {
          console.log("err--->" + err)
          this.showErrorAlert();
        })
    }
  }

  render() {
    const { CommerceJson } = this.state;
    const handleClick = () => {
      console.log('clicked');
      this.setState({
        classadd: !this.state.classadd
      })
    };
    const culture = {
      items: 1,
      responsive: {
        480: { items: 2 },
        320: { items: 1, }
      },
      loop: true
    };

    const servSlide = {
      items: 1,
      responsive: {
        320: { items: 1, }
      },
      loop: true,
      autoplay: true
    };

    return (
      <>
        <Header headerClass={'serv-head dd'} />
        <Helmet>
          <title>Expert SEO Services for Lawyers by iQuinceSoft - Drive More Traffic
          </title>
          <meta  name="description" content="Need SEO services for your law firm? iQuinceSoft provides specialized SEO solutions for lawyers to improve their online presence, generate leads, and drive more traffic to their website. Contact us now!" />
          <meta  name="robots" content="noindex,nofollow" />
        </Helmet>
        <div className="SEO_banner">
          <div className="SEO_sec">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-9">
                  <div className="page-title">
                    <h1>Maximizing Your Online Presence:<br /><span>SEO Services for Law Firm Websites</span></h1>
                    <div className='page-subtitle'>
                      <h2>Are you struggling with your low-ranking lawyer website?</h2>
                    </div>
                    <p>We can help! We provide SEO services for lawyers. Rank higher on the search engine result pages with our exemplary SEO for lawyer services.</p>
                    <Popup className='plan-popup' trigger={<button className="book-btn" onClick={() => handleClick()} type="button">Hire SEO Specialist</button>}
                      modal >
                      {close => (
                        <div className="modal">
                          <button className="close" onClick={close}>&times; </button>
                          <div className="content">
                            <h3>Download SEO <span>Package</span></h3>
                            <form>
                              <div className="in-txt"><FaUser /> <input onChange={e => this.setState({ name: e.target.value })} className="box-3" type="text" name="" placeholder="Name" /></div>
                              <div className="in-txt"><FaEnvelope /> <input onChange={e => this.setState({ email: e.target.value })} className="box-3" type="email" name="" placeholder="Work e-Mail" /></div>
                              <div className="in-txt"><FaPhoneAlt /> <input onChange={e => this.setState({ number: e.target.value })} className="box-3" type="number" name="" placeholder="Phone Number" /></div>
                              <div className="in-txt"><FaBuilding /> <input onChange={e => this.setState({ Company: e.target.value })} className="box-3" type="text" name="" placeholder="Company" /></div>
                              <div className="in-txt"><MdMessage /> <textarea onChange={e => this.setState({ message: e.target.value })} className="box-3" rows="4" placeholder="Message / Requirement"></textarea></div>
                              <Link style={{ width: '100%', textAlign: 'center' }} className="book_btn_form_button" onClick={() => this.submitForm()} to="#">Download</Link>
                            </form>
                          </div>
                        </div>
                      )
                      }
                    </Popup>
                  </div>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-5 col-md-3">
                  <img src={SEO_page_animated_image} alt="Loti File SEO Page" width="100%" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <AwardSec />


        <div className="SEO_welcome_sec reverse_row">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className='page-title'>
                  <h1>What is Law Firm <span> SEO?</span></h1>
                  <p>Law Firm SEO is the process of optimizing your law firm website. SEO for law firm can help your website rank high on search engines. By doing so, you can enhance your law firm’s online visibility. You can even improve the lead quality and optimize conversions with the help of SEO for lawyers.</p>
                  <p>A law firm’s website usually lacks optimized content, low visibility, and clear user intent to convert leads. An SEO service for law firms ensures that the content is optimized with images and technical SEO to achieve the best results.
                    SEO for lawyers attracts potential clients, enhances the online reputation, and boosts the conversion rate for your law firm's website.</p>
                  <p>If you're looking to improve your online presence and drive more traffic to your website, you've come to the right place. Contact us today to learn more about how our affordable SEO services can benefit your business.</p>
                </div>
              </div>
              <div className="col-lg-5 col-md-12" >
                <img src={Firm_SEO} alt="Law Firm" width="100%" />
              </div>
            </div>
          </div>
        </div>


        <div className="SEO_welcome_sec" style={{ background: '#F7F7F7' }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-12" >
                <img src={Law_Firm} alt="Law Firm" width="100%" />
              </div>
              <div className="col-lg-7 col-md-12">
                <div className='page-title'>
                  <h1>Why are SEO Services for <span>Law Firms Important? </span></h1>
                  <p>SEO services for law firms are important because of the following services:</p>
                  <ul>
                    <li><img src={check} alt="icon_check" />Increased website traffic: SEO services can help a law firm drive more traffic. It can lead to high conversion rates.</li>
                    <li><img src={check} alt="icon_check" />Measurable Results: SEO for lawyers can help them with measurable outcomes and help law firms track their SEO efforts' success.</li>
                    <li><img src={check} alt="icon_check" />Long-term Investment: SEO services are a long-term investment in a law firm’s online presence. It can drive traffic and generate quality leads for years.</li>
                    <li><img src={check} alt="icon_check" />Enhanced Brand Awareness: A law firm’s brand awareness can be improved by using SEO services for law firms. These services can help a law firm stand out in the overcrowded marketplace.</li>
                    <li><img src={check} alt="icon_check" />Increased Referrals: A robust online presence leads to improved referrals from the existing clients. These existing clients recommend a law firm to the target audience.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="SEO_welcome_sec reverse_sec_2">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className='page-title'>
                  <h1>What Are the Benefits of<span> Law Firm SEO Services? </span></h1>
                  <p>The benefits of hiring a law firm SEO company can fetch tangible outcomes for you, which we write down in the following part. Have a look:</p>
                  <ul>
                    <li><img src={check} alt="icon_check" />Increased Online Visibility for Relevant Searches: SEO services for law firms can help lawyers and law firms with increased online visibility for relevant searches. It helps the searchers to find you as a service online.</li>
                    <li><img src={check} alt="icon_check" />More Targeted Traffic to the Website: The SEO for lawyers can help your business by optimizing the website for specific keywords. This can drive targeted traffic to the website and generate more leads with optimized conversions.</li>
                    <li><img src={check} alt="icon_check" />Cost-Effective Marketing: Optimizing your website is a cost-effective marketing option compared to traditional marketing methods. SEO services for law firms can help you generate more leads and reach a wider audience without breaking the bank.</li>
                    <li><img src={check} alt="icon_check" />Improved User Experience: SEO for lawyers can improve the user experience and help to navigate. This can drive high engagement rates and yield positive online reviews, improving the law firm's online reputation.</li>
                    <li><img src={check} alt="icon_check" />Competitive Advantage: Higher ranks on search engine result pages can help outsmart the competition and earn more online clicks. This can generate more qualified leads and improved conversion rates.</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-12" >
                <img src={SEO_FIRM} alt="Law Firm" width="100%" />
              </div>
            </div>
          </div>
        </div>

        <div className="Expert-sec">
          <div className="container">
            <div className="row" >
              <div className="col-lg-4 col-md-6">
                <h3> Our Expertise for Niche based lawyers</h3>
                <h2>We serve several law practices that include:</h2>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="Expert-txt">
                  <h4><span><Link to="#">SEO for <span>dui Attorneys</span></Link></span></h4>
                  <p>DUI lawyers are highly competitive in online searches and need comprehensive SEO services. SEO for DUI attorneys is a specialized job we are renowned for. If you look forward to hiring a DUI lawyer SEO, feel free to connect with iQuincesoft. We help you not just rank but stay on top for long.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="Expert-txt">
                  <h4><span><Link to="#" >SEO For <span>Criminal Defense Attorney</span></Link></span></h4>
                  <p> Hire iQuincesoft to boost your online visibility and get you found to the target audience with the help of our exemplary criminal defense SEO services. We help you fetch organic leads and help you convert them easily. We help you promote your services and get visible to those who search for the same with our search engine optimization for criminal defense attorney services. </p>

                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="Expert-txt">
                  <h4><span><Link to="#" >SEO for <span>Personal Injury Attorneys</span></Link></span></h4>
                  <p> Search engine optimization for personal injury lawyers’ websites can help you rank high and attain the number of cases to fight in a court of law. As a renowned personal injury SEO service, iQuincesoft can help you attain several cases with improved visibility, more traffic, and optimized leads.SEO for personal injury attorneys is a specialized service. Don’t ruin your online reputation by hiring any other amateur firm. Hire the experts; hire iQuincesoft.  </p>

                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="Expert-txt">
                  <h4><span><Link to="#" >Local SEO for <span>Law Firms</span></Link></span></h4>
                  <p> Local SEO for law firms is pivotal in reaching the local target audience. Law firm local SEO helps the audience find you and can drive them to your physical office. It helps to connect, communicate and resonate with the victims who need someone to handle insurance companies and represent them in a court of law. As a renowned local SEO services for lawyers, iQuincesoft can help the law firms find the target audience and fight for the right of the victims and help them. </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="Expert-txt">
                  <h4><span><Link to="#" >SEO for <span>Family Lawyer</span></Link></span></h4>
                  <p> SEO for family lawyer can help your practice get recognition across the target audience. SEO for family law attorneys can improve the online visibility of divorce and family attorneys.
                    SEO for family law firms can help you achieve business goals seamlessly. If you look forward to hiring search engine optimization for divorce lawyers, connect with iQuincesoft.
                  </p>

                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <OwlCarousel options={servSlide}>
                  <div className="Expert-txt">
                    <h4><span><Link to="#">SEO for <span>dui Attorneys</span></Link></span></h4>
                    <p>DUI lawyers are highly competitive in online searches and need comprehensive SEO services. SEO for DUI attorneys is a specialized job we are renowned for. If you look forward to hiring a DUI lawyer SEO, feel free to connect with iQuincesoft. We help you not just rank but stay on top for long.
                    </p>
                  </div>
                  <div className="Expert-txt">
                    <h4><span><Link to="#" >SEO For <span>Criminal Defense Attorney</span></Link></span></h4>
                    <p> Hire iQuincesoft to boost your online visibility and get you found to the target audience with the help of our exemplary criminal defense SEO services. We help you fetch organic leads and help you convert them easily. We help you promote your services and get visible to those who search for the same with our search engine optimization for criminal defense attorney services. </p>
                  </div>
                  <div className="Expert-txt">
                    <h4><span><Link to="#" >SEO for <span>Personal Injury Attorneys</span></Link></span></h4>
                    <p> Search engine optimization for personal injury lawyers’ websites can help you rank high and attain the number of cases to fight in a court of law. As a renowned personal injury SEO service, iQuincesoft can help you attain several cases with improved visibility, more traffic, and optimized leads.SEO for personal injury attorneys is a specialized service. Don’t ruin your online reputation by hiring any other amateur firm. Hire the experts; hire iQuincesoft.  </p>
                  </div>
                  <div className="Expert-txt">
                    <h4><span><Link to="#" >Local SEO for <span>Law Firms</span></Link></span></h4>
                    <p> Local SEO for law firms is pivotal in reaching the local target audience. Law firm local SEO helps the audience find you and can drive them to your physical office. It helps to connect, communicate and resonate with the victims who need someone to handle insurance companies and represent them in a court of law. As a renowned local SEO services for lawyers, iQuincesoft can help the law firms find the target audience and fight for the right of the victims and help them. </p>
                  </div>
                  <div className="Expert-txt">
                    <h4><span><Link to="#" >SEO for <span>Family Lawyer</span></Link></span></h4>
                    <p> SEO for family lawyer can help your practice get recognition across the target audience. SEO for family law attorneys can improve the online visibility of divorce and family attorneys.
                      SEO for family law firms can help you achieve business goals seamlessly. If you look forward to hiring search engine optimization for divorce lawyers, connect with iQuincesoft.
                    </p>
                  </div>
                </OwlCarousel>
              </div>
            </div >
          </div>
        </div>

        <div className="Letstalk-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div className="Letstalk-sec-txt">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="Letstalk-sec-txt-2">
                          <img alt="img" width="50%" src={Letstalk} />
                          <p><span>We’re actual humans here on the other side 👋</span></p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="Letstalk-sec-txt-1">
                          <h1>Schedule a 15 minutes call to discuss your requirement</h1>
                          <PopupButton className="book-btn-2" type="button"
                            url="https://calendly.com/iquincesoft-sales/30min"
                            /*
                                            * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                                            * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                                            */
                            rootElement={document.getElementById("root")}
                            text-color="#00ccff"
                            text="Schedule an Appointment"
                          />

                          <p className="para-link-2"><Link to={'#EuquireSec'} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })} ><span>Or, Use this form to share your requirements.
                            Get guaranteed response within 8 Hrs.</span></Link></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




        <div className="SEO-sec">
          <div className="container">
            <div className="page-title">
              <h1>Boost Your Law Firm's Online Presence with <span> Affordable SEO Services </span></h1>
              <p> As a law firm, it's crucial to have a strong online presence to attract potential clients and stay ahead of the <br /> competition. That's where our affordable SEO services come in. We specialize in providing SEO<br /> services tailored to meet the unique needs of law firms, including:</p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="SEO-txt-1">
                  <h4><span><img src={Keyword_research} alt="Keyword_research" /></span><Link to="#" >Keyword Research and Analysis</Link></h4>
                  <ul>
                    <li>We identify relevant and high-volume search terms for your law firm, such as "personal injury lawyer" or "divorce attorney." By optimizing your website content for these targeted keywords, we can improve your search engine rankings and attract more organic traffic to your website.</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="SEO-txt-1">
                  <h4><span><img src={Page_Optimization} alt="On-Page Optimization" /></span><Link to="#" >On-Page Optimization</Link></h4>
                  <ul>
                    <li> We'll optimize your law firm's website for search engines by improving its structure, content, and HTML tags. This will enhance user experience and speed, which are important factors for search engine rankings. By implementing these strategies, we can increase your search engine rankings and attract more organic traffic.</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="SEO-txt-1">
                  <h4><span><img src={Off_Page_Optimization} alt="Off-Page Optimization" /></span><Link to="#" >Off-Page Optimization</Link></h4>
                  <ul>
                    <li>  Our team will build high-quality backlinks from reputable websites to your law firm's website. This will improve your website's authority and credibility, which will boost your search engine rankings and attract more organic traffic to your website.</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="SEO-txt-1">
                  <h4><span><img src={Technical_Seo} alt="Technical_Seo" /></span><Link to="#" >Technical_Seo</Link></h4>
                  <ul>
                    <li> We will ensure your law firm's website is properly structured and crawlable by search engines. Our team will fix any technical issues that may negatively impact your search engine rankings, while improving your website's user experience to attract more organic traffic.</li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="SEO-txt-1">
                  <h4><span><img src={Local_Seo} alt="Local SEO" /></span><Link to="#" >Local SEO</Link></h4>
                  <ul>
                    <li>  Our team will optimize your law firm's website for local search queries, such as "personal injury lawyer in [city name]." By improving your visibility in local search results, we can attract more local clients to your law firm.</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="SEO-txt-1">
                  <h4><span><img src={Content_Marketing} alt="Content Marketing" /></span><Link to="#" >Content Marketing</Link></h4>
                  <ul>
                    <li> We will create high-quality and engaging content for your law firm's website. This will attract more visitors to your website, increase user engagement, and ultimately boost your search engine rankings and attract more organic traffic.</li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-12 col-md-12'>
                <OwlCarousel options={servSlide}>
                  <div className="SEO-txt-1">
                    <h4><span><img src={Off_Page_Optimization} alt="Off-Page Optimization" /></span><Link to="#" >Off-Page Optimization</Link></h4>
                    <ul>
                      <li>  Build high-quality backlinks from reputable websites</li>
                      <li> Improve your website's authority and credibility</li>
                      <li> Boost your search engine rankings and attract more organic traffic
                      </li>
                    </ul>
                  </div>
                  <div className="SEO-txt-1">
                    <h4><span><img src={Page_Optimization} alt="On-Page Optimization" /></span><Link to="#" >On-Page Optimization</Link></h4>
                    <ul>
                      <li>  Optimize your website's structure, content, and HTML tags for search engines</li>
                      <li> Improve user experience and website speed</li>
                      <li> Boost your search engine rankings and attract more organic traffic</li>
                    </ul>
                  </div>
                  <div className="SEO-txt-1">
                    <h4><span><img src={Off_Page_Optimization} alt="Off-Page Optimization" /></span><Link to="#" >Off-Page Optimization</Link></h4>
                    <ul>
                      <li>  Build high-quality backlinks from reputable websites</li>
                      <li> Improve your website's authority and credibility</li>
                      <li> Boost your search engine rankings and attract more organic traffic
                      </li>
                    </ul>
                  </div>
                  <div className="SEO-txt-1">
                    <h4><span><img src={Technical_Seo} alt="Technical_Seo" /></span><Link to="#" >Technical_Seo</Link></h4>
                    <ul>
                      <li>  Making a well-structured and crawlable website</li>
                      <li>  Removing negative impact by fixing technical issues</li>
                      <li> Improving user experience to drive organic trafffic.</li>
                    </ul>
                  </div>
                  <div className="SEO-txt-1">
                    <h4><span><img src={Local_Seo} alt="Local SEO" /></span><Link to="#" >Local SEO</Link></h4>
                    <ul>
                      <li>  Optimizing your website for local search queries</li>
                      <li> Improving your visibility in local search results</li>
                      <li>Attracting more local customers to your business</li>
                    </ul>
                  </div>
                  <div className="SEO-txt-1">
                    <h4><span><img src={Content_Marketing} alt="Content Marketing" /></span><Link to="#" >Content Marketing</Link></h4>
                    <ul>
                      <li>  Creating high-quality and engaging content for your website</li>
                      <li> Attracting more visitors website and increase user engagement</li>
                      <li>Boosting your search engine rankings and attract more organic traffic</li>
                    </ul>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row'>
              <div className='col-lg-1 col-md-1'></div>
              <div className='col-lg-9 col-md-9 col-sm-12'>
                <div className='page-title-2'>
                  <p>By implementing these SEO services, we can help your law firm attract more organic traffic, improve your website's user experience, and ultimately drive more leads and sales to your business. Contact us to learn more about how our affordable SEO services can benefit your law firm.</p>
                  <Popup className='plan-popup' trigger={<button className="book-btn" onClick={() => handleClick()} type="button">Enquire Now</button>}
                    modal >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>&times; </button>
                        <div className="content">
                          <h3>Download SEO <span>Package</span></h3>
                          <form>
                            <div className="in-txt"><FaUser /> <input onChange={e => this.setState({ name: e.target.value })} className="box-3" type="text" name="" placeholder="Name" /></div>
                            <div className="in-txt"><FaEnvelope /> <input onChange={e => this.setState({ email: e.target.value })} className="box-3" type="email" name="" placeholder="Work e-Mail" /></div>
                            <div className="in-txt"><FaPhoneAlt /> <input onChange={e => this.setState({ number: e.target.value })} className="box-3" type="number" name="" placeholder="Phone Number" /></div>
                            <div className="in-txt"><FaBuilding /> <input onChange={e => this.setState({ Company: e.target.value })} className="box-3" type="text" name="" placeholder="Company" /></div>
                            <div className="in-txt"><MdMessage /> <textarea onChange={e => this.setState({ message: e.target.value })} className="box-3" rows="4" placeholder="Message / Requirement"></textarea></div>
                            <Link style={{ width: '100%', textAlign: 'center' }} className="book_btn_form_button" onClick={() => this.submitForm()} to="#">Download</Link>
                          </form>
                        </div>
                      </div>
                    )
                    }
                  </Popup>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className='SEO-plan'>
          <div className="page-title">
            <h1>Explore Our Seo <span>Packages, Plans And Pricing</span></h1>
            <p> Generate leads online that works for your business</p>
          </div>
          <div className='container'>
            <div className='row'>

              <div className='col-lg-3 col-md-4'>
                <div className='SEO-plan-sec'>
                  <div className='SEO-plan-sec-1'>
                    <h1>Basic Boost</h1>
                  </div>
                  <div className='SEO-plan-sec-2'>
                    <h1><span>Keywords</span><br />Up to 10</h1>
                    <h1><span>Page Optimization</span><br />20 Web Page (Max)</h1>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-4'>
                <div className='SEO-plan-sec'>
                  <div className='SEO-plan-sec-1'>
                    <h1>Enhanced Edge</h1>
                  </div>
                  <div className='SEO-plan-sec-2'>
                    <h1><span>Keywords</span><br />Up to 20</h1>
                    <h1><span>Page Optimization</span><br />30 Web Page (Max)</h1>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-4'>
                <div className='SEO-plan-sec'>
                  <div className='SEO-plan-sec-1'>
                    <h1>Premium Pulse</h1>
                  </div>
                  <div className='SEO-plan-sec-2'>
                    <h1><span>Keywords</span><br />Up to 40</h1>
                    <h1><span>Page Optimization</span><br />50 Web Page (Max)</h1>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-4'>
                <div className='SEO-plan-sec'>
                  <div className='SEO-plan-sec-1'>
                    <h1>Ultimate Upshot</h1>
                  </div>
                  <div className='SEO-plan-sec-2'>
                    <h1><span>Keywords</span><br />Up to 100</h1>
                    <h1><span>Page Optimization</span><br />100 Web Page (Max)</h1>
                  </div>
                </div>
              </div>
              <div className='col-lg-12 col-md-12'>
                <OwlCarousel options={servSlide}>
                  <div className='SEO-plan-sec'>
                    <div className='SEO-plan-sec-1'>
                      <h1>Basic Boost</h1>
                    </div>
                    <div className='SEO-plan-sec-2'>
                      <h1><span>Keywords</span><br />Up to 10</h1>
                      <h1><span>Page Optimization</span><br />20 Web Page (Max)</h1>
                    </div>
                  </div>
                  <div className='SEO-plan-sec'>
                    <div className='SEO-plan-sec-1'>
                      <h1>Enhanced Edge</h1>
                    </div>
                    <div className='SEO-plan-sec-2'>
                      <h1><span>Keywords</span><br />Up to 20</h1>
                      <h1><span>Page Optimization</span><br />30 Web Page (Max)</h1>
                    </div>
                  </div>
                  <div className='SEO-plan-sec'>
                    <div className='SEO-plan-sec-1'>
                      <h1>Ultimate Upshot</h1>
                    </div>
                    <div className='SEO-plan-sec-2'>
                      <h1><span>Keywords</span><br />Up to 100</h1>
                      <h1><span>Page Optimization</span><br />100 Web Page (Max)</h1>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
            <Popup className='plan-popup' trigger={<button className="book-btn" onClick={() => handleClick()} type="button">View More</button>}
              modal >
              {close => (
                <div className="modal">
                  <button className="close" onClick={close}>&times; </button>
                  <div className="content">
                    <h3>Download SEO <span>Package</span></h3>
                    <form>
                      <div className="in-txt"><FaUser /> <input onChange={e => this.setState({ name: e.target.value })} className="box-3" type="text" name="" placeholder="Name" /></div>
                      <div className="in-txt"><FaEnvelope /> <input onChange={e => this.setState({ email: e.target.value })} className="box-3" type="email" name="" placeholder="Work e-Mail" /></div>
                      <div className="in-txt"><FaPhoneAlt /> <input onChange={e => this.setState({ number: e.target.value })} className="box-3" type="number" name="" placeholder="Phone Number" /></div>
                      <div className="in-txt"><FaBuilding /> <input onChange={e => this.setState({ Company: e.target.value })} className="box-3" type="text" name="" placeholder="Company" /></div>
                      <div className="in-txt"><MdMessage /> <textarea onChange={e => this.setState({ message: e.target.value })} className="box-3" rows="4" placeholder="Message / Requirement"></textarea></div>
                      <Link style={{ width: '100%', textAlign: 'center' }} className="book_btn_form_button" onClick={() => this.submitForm()} to="#">Download</Link>
                    </form>
                  </div>
                </div>
              )
              }
            </Popup>
          </div>

        </div>


        <div className='Our-SEO-Process' style={{ background: '#fff' }}>
          <div className="page-title">
            <h1>Our Process <span>for SEO Services for Lawyers</span></h1>
            <p> At our firm, we offer cost-effective SEO packages that are tailored specifically for lawyers looking to enhance their <br />online presence. Our SEO process includes the following steps:</p>
          </div>

          <div className='container'>
            <div className='row'>
              <div className='col-lg-1'></div>
              <div className='col-lg-10 col-md-12'>
                <Tabs>
                  <TabList >
                    <Tab><img src={Analysis} alt='Analysis' />Analysis</Tab>
                    <Tab><img src={Strategy} alt='Strategy' />Strategy</Tab>
                    <Tab><img src={Implementation} alt='Implementation' />Implementation</Tab>
                    <Tab><img src={Dollar} alt='Results, Rinse, Repeat' />Results, Rinse, Repeat</Tab>
                  </TabList>

                  <TabPanel>
                    <div className='row'>
                      <div className='col-lg-6 col-md-6'>
                        <div className='Our-SEO-Process-sec'>
                          <p>We start by conducting website audits, competitor research, and keyword analysis to gain insights into areas for improvement, identify competitors, and establish a baseline for future progress. We also analyze your firm's target audience and demographics to better understand how they search for legal services online.</p>
                        </div>
                      </div>
                      <div className='col-lg-1'></div>
                      <div className='col-lg-5 col-md-6'>
                        <img src={analysis_digital_marketing} width="100%" alt="analysis-digital-marketing" />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className='row'>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='Our-SEO-Process-sec'>
                          <p>Based on our analysis, we create an affordable search engine optimization strategy specifically for your law firm. Our strategy outlines goals, tactics, and timelines for achieving desired results. This approach ensures that our SEO services align with your business objectives and provide a clear roadmap for success. Our SEO services for lawyers include consulting, content planning, and link building.</p>
                        </div>
                      </div>
                      <div className='col-lg-1'></div>
                      <div className='col-lg-5 col-md-6'>
                        <img src={strategy_planning} width="100%" alt="analysis-digital-marketing" />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className='row'>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='Our-SEO-Process-sec'>
                          <p>In this phase, we put our SEO strategy into action. We optimize your website's content, build links, and monitor progress to improve your firm's search engine visibility and increase website traffic. Our SEO services related to implementation include on-page optimization, link building, and content creation, tailored to meet the needs of your law firm.</p>
                        </div>
                      </div>
                      <div className='col-lg-1'></div>
                      <div className='col-lg-5 col-md-6'>
                        <img src={employee_implementing_business_idea} width="100%" alt="analysis-digital-marketing" />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className='row'>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='Our-SEO-Process-sec'>
                          <p>Finally, we measure and analyze the results of our implementation and make necessary adjustments to optimize performance continually. We track progress towards your firm's goals, identify areas for improvement, and refine the SEO strategy accordingly. Our SEO services related to results include analytics reporting, performance tracking, and ongoing SEO maintenance for your law firm.</p>
                        </div>
                      </div>
                      <div className='col-lg-1'></div>
                      <div className='col-lg-5 col-md-6'>
                        <img src={seo_Plan} width="100%" alt="analysis-digital-marketing" />
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
            <div className='process-section'>
              <div className='row'>
                <div className='col-lg-1'></div>
                <div className="col-lg-10 col-md-12">
                  <hr style={{ margin: '30px 0' }} />
                  <p>Finally, we measure and analyze the results of our implementation and make necessary adjustments to optimize performance continually. We track progress towards your firm's goals, identify areas for improvement, and refine the SEO strategy accordingly. Our SEO services related to results include analytics reporting, performance tracking, and ongoing SEO maintenance for your law firm.</p>
                </div>
              </div>
            </div>
          </div>

          <ReviewSec />

          <div className='ParallelX_image'>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="SEO-Maximize-sec">
                    <p>By following this process, our team of SEO experts can help your law firm improve its online presence, attract more clients, and grow your business. Contact us today to learn more about our affordable SEO services for lawyers.</p>
                    <Link className="book-btn" type="button" to={"/contact-us"}>ContactUs</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="frequent-sec">
          <h3>Frequently Asked Questions</h3>
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-1"></div>
              <div className="col-lg-8 col-md-10" >
                <Accordion allowZeroExpanded preExpanded={['a']}>
                  <AccordionItem>
                    <AccordionItemButton>
                      What kind of results can I expect from SEO services?
                    </AccordionItemButton>
                    <AccordionItemPanel>
                      SEO is a long-term strategy that takes time to show results. The specific results that a website can expect will depend on a variety of factors, such as the competition in the industry, the website's current state, and the SEO strategy being implemented. However, typical results include increased website traffic, higher search engine rankings, and improved website visibility.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemButton>
                      How long does it take to see results from SEO services?
                    </AccordionItemButton>
                    <AccordionItemPanel>
                      SEO is a long-term strategy, and results can take anywhere from a few months to a year or more to fully manifest. However, some improvements can be seen in the first few months of the campaign. It's important to note that SEO is an ongoing process, and results may vary depending on the specific goals and needs of each website.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemButton>
                      How much do SEO services cost?
                    </AccordionItemButton>
                    <AccordionItemPanel>
                      The cost of SEO services can vary depending on the scope of the project, the level of competition in the industry, and the specific needs and goals of the website. Some SEO companies may charge a monthly retainer fee, while others may charge a flat fee for a specific project or campaign. It's important to get a clear understanding of the costs involved before signing up for any SEO services.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemButton>
                      What kind of SEO techniques do you use?
                    </AccordionItemButton>
                    <AccordionItemPanel>
                      SEO techniques can vary depending on the specific needs and goals of each website, as well as the current state of the website's SEO. Some common SEO techniques include keyword research and optimization, on-page optimization, link building, technical SEO, and content marketing. We use a variety of SEO techniques to help our clients achieve their goals, and we customize our approach based on the specific needs of each client.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemButton>
                      How do you measure the success of your SEO campaigns?
                    </AccordionItemButton>
                    <AccordionItemPanel>
                      We measure the success of our SEO campaigns by tracking key metrics such as website traffic, search engine rankings, and conversion rates. We use analytics tools such as Google Analytics and Ahrefs to monitor progress and make data-driven decisions about our SEO strategy. We also provide regular reports to our clients to keep them informed about the progress of their SEO campaigns.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemButton>
                      Will I be able to see the work that you're doing?
                    </AccordionItemButton>
                    <AccordionItemPanel>
                      We believe in transparency and communication with our clients, and we provide regular updates on the work that we're doing. We may also provide access to tools such as Google Analytics and Google Search Console so that clients can see the progress of their campaigns in real-time. We are always available to answer any questions that clients may have about the work that we're doing.
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>


        <div className="culture-sec insight-sec">
          <h3>Featured Insights</h3>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                {CommerceJson.length && (
                  <OwlCarousel options={culture}>
                    {CommerceJson.map(CommerceJsonS => {
                      return (
                        <div className="cult-box">
                          <div className="tech-dot"></div>
                          <h4>{CommerceJsonS.name}</h4>
                          <p dangerouslySetInnerHTML={{ __html: CommerceJsonS.content.substr(0, 100) }}></p>
                          <Link to={`/PostPage/${CommerceJsonS.slug}`} >READ MORE </Link>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                )}

                <div className="row">
                  {CommerceJson && CommerceJson.map((CommerceJsonS, index) => {
                    return (
                      <div className="col-lg-4 col-md-4">
                        <div className="insight-box" key={index}>
                          <h4>{CommerceJsonS.name}</h4>
                          <p dangerouslySetInnerHTML={{ __html: CommerceJsonS.content.substr(0, 100) }}></p>
                          <Link to={`/PostPage/${CommerceJsonS.slug}`} >READ MORE </Link>
                        </div>
                      </div>

                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <FormPopup />
        </div>
        <Footer />

      </>
    );
  }
}

export default SEO_Law_firm;

