import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Euquire } from '../json/euquire';
import axios from 'axios';
import { TailSpin } from 'react-loader-spinner'
import { FaRegUserCircle, FaRegEnvelope, FaPhoneAlt, FaEnvelopeOpenText, FaBuilding } from 'react-icons/fa';
import Swal from "sweetalert2";
import OwlCarousel from 'react-owl-carousel2';
import google_cloud from '../images/google_cloud.png';
import aws from '../images/AWS.png';
import bitrix from '../images/bitrix.png';
import WIX_Partner from '../images/WIX_Partner.jpg';


class EuquireSec extends Component {

	constructor(props) {
		super(props)
		this.state = {
			euquireJson: [],
			name: "",
			email: "",
			number: "",
			Company: "",
			message: "",
			file: null,
			Loading: true,
		}
	}

	componentDidMount() {
		Euquire.getEuquire().then((euquire, err) => {
			if (!err) {
				this.setState({
					euquireJson: euquire,
				});
			}
		});
	}

	showAlert = () => {
		Swal.fire({
			title: "Thank you!",
			text: "We have received your message. Someone from our team will contact you soon.",
			icon: "success",
			confirmButtonText: "OK",
		}).then(() => {
			setTimeout(() => {
				window.location.reload();
			}, 2000)
		});
	}

	showErrorAlert = () => {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'Something went wrong!',
			footer: '<a href="">Why do I have this issue?</a>'
		}).then(() => {
			setTimeout(() => {
				window.location.reload();
			}, 1000)
		})
	}

	showLoading = () => {
		if (this.state.Loading) {
			return (
				<div className="loader">
					<TailSpin
						color="#00BFFF"
						height={100}
						width={100}
						timeout={3000} //3 secs
					/>
				</div>
			)
		}
	}



	submitForm = e => {
		if (this.state.name === '' || this.state.email === '' || this.state.number === '') {
			alert('Please Fill Required All Fields');
		}
		else {
			this.showLoading();
			let formData = new FormData()
			formData.set("your-name", this.state.name)
			formData.set("your-email", this.state.email)
			formData.set("number", this.state.number)
			formData.set("Companyname", this.state.Company)
			formData.set("your-message", this.state.message)
			formData.append("Document", this.state.file)
			axios.post('https://iquincesoft.com/iqsandbox/wp-json/contact-form-7/v1/contact-forms/1675/feedback', formData, {
				headers: {
					"content-type": "multipart/form-data",
				},
			})
				.then(res => {
					if (res.data.status === "mail_sent") {
						this.setState({ Loading: false })
						this.showAlert();
						this.setState({
							name: "",
							email: "",
							number: "",
							Company: "",
							message: "",
							file: null,
						})
					}
				}).catch(err => {
					console.log("err--->" + err)
					this.showErrorAlert();
				})
		}
	}

	render() {
		const { euquireJson } = this.state;
		const servSlide = {
			items: 1,
			responsive: {
				320: { items: 1, }
			},
			loop: true,
			nav: true,
			autoplay: true
		};
		return (
			<>
				<div className="euquire-sec" id="EuquireSec">
					<div className="container">
						<div className="row">
							<div className="col-lg-1"></div>
							<div className="col-lg-10 col-md-12">
							 <div className="enq-box">
							  <div className="row">
								<div className="col-lg-5 col-md-6">
								<div className="form-box">
									{euquireJson.length && euquireJson.map(euquireJsons => {
										return (
											<div key={euquireJsons.id}>
												<h3 dangerouslySetInnerHTML={{ __html: euquireJsons.name }}></h3>
												<p dangerouslySetInnerHTML={{ __html: euquireJsons.content }}></p>
											</div>
										);
									})}
									<div className="form_section">
												<OwlCarousel options={servSlide}>
													<img alt="google_cloud" width="340px" title="google_cloud" src={google_cloud} />
													<img alt="aws" title="aws" width="340px" src={aws} />
													<img alt="bitrix" title="bitrix" width="340px" src={bitrix} />
													<img alt="WIX_Partner" title="WIX_Partner" width="340px" src={WIX_Partner} />
												</OwlCarousel>

									</div>
								</div>
							</div>
							<div className="col-lg-7 col-md-6">
								<form style={{ position: 'relative' }}>
									<h3>Drop us a line, and our rep will contact you within 2 hours to arrange an initial discussion</h3>
									<div className="in-box"><FaRegUserCircle /> <input onChange={e => this.setState({ name: e.target.value })} value={this.state.name} className="box" type="text" name="" placeholder="Name*" /></div>
									<div className="in-box"><FaRegEnvelope /> <input onChange={e => this.setState({ email: e.target.value })} value={this.state.email} className="box" type="email" name="" placeholder="Work e-Mail*" /></div>
									<div className="in-box"><FaPhoneAlt /> <input onChange={e => this.setState({ number: e.target.value })} value={this.state.number} className="box" type="number" name="" placeholder="Phone Number*" /></div>
									<div className="in-box"><FaBuilding /> <input onChange={e => this.setState({ Company: e.target.value })} value={this.state.Company} className="box" type="text" name="" placeholder="Company" /></div>
									<div className="in-box"><FaEnvelopeOpenText /> <textarea onChange={e => this.setState({ message: e.target.value })} value={this.state.message} className="box" rows="3" placeholder="Message / Response*"></textarea></div>
									{/* <label style={{ fontWeight: "600", borderBottom: "1px solid black" }} for="files">Attach files if any</label> */}
									<input placeholder="Attach files if any." title="Choose a Resume please" id="files" name="files" type="file" accept=".png, .jpg, .pdf" onChange={e => this.setState({
										file: e.target.files[0]
									})} />
									<Link onClick={() => this.submitForm()} to="#EuquireSec">Enquire Now</Link>
								</form>
							</div>
							  </div>
							 </div>
							</div>
						</div>
					</div>
				</div >
			</>
		);
	}
}

export default EuquireSec;