import React from 'react';
import Header from './Header';
import SEO_page_animated_image from '../images/animation_640.gif'
import { PopupButton } from "react-calendly";
import { HashLink as Link } from 'react-router-hash-link';
import Letstalk from '../images/Letstalk.png';
import check from '../images/Check.svg';
import 'react-calendar/dist/Calendar.css';
import OwlCarousel from 'react-owl-carousel2';
import Keyword_research from '../images/Keyword_Research.png';
import Footer from './Footer';
import ReviewSec from '../container/ReviewSec';
import { FaAngleDoubleRight } from 'react-icons/fa';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Analysis from '../images/analysis.svg';
import Strategy from '../images/Strategy.svg';
import Implementation from '../images/Implementation.svg';
import Dollar from '../images/Dollar.svg';
import analysis_digital_marketing from '../images/analysis-digital-marketing.png';
import strategy_planning from '../images/strategy_planning.png';
import employee_implementing_business_idea from '../images/employee_implementing_business_idea.png';
import seo_Plan from '../images/seo_Plan.png';
import Page_Optimization from '../images/Page_Optimization.png';
import Off_Page_Optimization from '../images/Off_Page_Optimization.png';
import Technical_Seo from '../images/Technical_Seo.png';
import Local_Seo from '../images/Local_Seo.png';
import Content_Marketing from '../images/Content_Marketing.png';
import E_commerce_SEO from '../images/E_commerce_SEO.png';
import Global_seo from '../images/Global_seo.png';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from 'axios';
import { FaUser, FaEnvelope, FaPhone, FaBuilding } from 'react-icons/fa';
import { MdMessage, FaPhoneAlt } from 'react-icons/all';
import { PostCate } from '../json/PostCate';
import { TailSpin } from 'react-loader-spinner'
import { Helmet } from "react-helmet";
import FormPopup from '../container/FormPopup';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import AwardSec from '../container/AwardSec';
import Swal from "sweetalert2";

class SEOPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      number: "",
      Company: "",
      message: "",
      Loading: true,
      classadd: false,
      servicesJson: [],
      appjson: [],
      Questionjson: [],
      CommerceJson: [],
      Loading: true,
    }
  }

  componentDidMount() {
    PostCate.getWoocommerce().then((wooCommerce, err) => {
      if (!err) {
        this.setState({
          CommerceJson: wooCommerce,
          Loading: false,
        });
      }
    });
  }

  showAlert = () => {
    Swal.fire({
      title: "Thank you!",
      text: `We received your application for ${this.state.Developer_Position}. We appreciate you taking the time to apply.`,
      icon: "success",
      confirmButtonText: "OK",
    }).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 2000)
    });
  }
  showErrorAlert = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: '<a href="">Why do I have this issue?</a>'
    }).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 1000)
    })
  }

  showLoading = () => {
    if (this.state.Loading) {
      return (
        <div className="loader">
          <TailSpin
            color="#00BFFF"
            height={100}
            width={100}
            timeout={3000} //3 secs
          />
        </div>
      )
    }
  }

  submitForm = e => {

    if (this.state.name === '' || this.state.email === '' || this.state.number === '') {
      alert('Please Fill Required All Fields');
    }
    else {
      this.showLoading();
      let formData = new FormData()
      formData.set("FullName", this.state.name)
      formData.set("Email", this.state.email)
      formData.set("Mob_Number", this.state.number)
      formData.set("My_Company", this.state.Company)
      formData.set("your_message", this.state.message)
      axios.post('https://iquincesoft.com/iqsandbox/wp-json/contact-form-7/v1/contact-forms/2174/feedback', formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
        .then(res => {
          if (res.data.status === "mail_sent") {
            this.setState({ Loading: false })
            this.showAlert();
            this.setState({
              name: "",
              email: "",
              number: "",
              Company: "",
              message: "",
            })
          }
        }).catch(err => {
          console.log("err--->" + err)
          this.showErrorAlert();
        })
    }
  }


  render() {
    const { CommerceJson } = this.state;
    const handleClick = () => {
      console.log('clicked');
      this.setState({
        classadd: !this.state.classadd
      })
    };


    const culture = {
      items: 1,
      responsive: {
        480: { items: 2 },
        320: { items: 1, }
      },
      loop: true
    };

    const servSlide = {
      items: 1,
      responsive: {
        320: { items: 1, }
      },
      loop: true,
      autoplay: true
    };
    return (
      <>
        <Header headerClass={'serv-head dd'} />
        <Helmet>
          <title>Affordable SEO Services by iQuinceSoft - Boost Your Online Visibility
          </title>
          <meta name="description" content="Looking for budget-friendly SEO services? iQuinceSoft offers affordable SEO solutions to help businesses increase their online visibility and attract more customers. Get started today!" />
        </Helmet>

        <div className="SEO_banner">
          <div className="SEO_sec">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-9">
                  <div className="page-title">
                    <h1>Affordable SEO Services<br /><span>for Increased Visibility</span></h1>
                    <div className='page-subtitle'>
                      <h2>Say Goodbye to Invisible Websites: Our SEO Experts Will Put Your Business in
                        the Spotlight!</h2>
                    </div>
                    <p>In the digital age, having a website is essential, but if it's not visible, it won't
                      help your business. Our SEO experts will optimize your website and improve
                      your search engine rankings, making it easier for your target audience to find
                      you online. Say goodbye to being an invisible website and hello to being in the
                      spotlight with our help.</p>
                    <Popup className='plan-popup' trigger={<button className="book-btn" onClick={() => handleClick()} type="button">Hire SEO Specialist</button>}
                      modal >
                      {close => (
                        <div className="modal">
                          <button className="close" onClick={close}>&times; </button>
                          <div className="content">
                            <h3>Download SEO <span>Package</span></h3>
                            <form>
                              <div className="in-txt"><FaUser /> <input onChange={e => this.setState({ name: e.target.value })} className="box-3" type="text" name="" placeholder="Name" /></div>
                              <div className="in-txt"><FaEnvelope /> <input onChange={e => this.setState({ email: e.target.value })} className="box-3" type="email" name="" placeholder="Work e-Mail" /></div>
                              <div className="in-txt"><FaPhoneAlt /> <input onChange={e => this.setState({ number: e.target.value })} className="box-3" type="number" name="" placeholder="Phone Number" /></div>
                              <div className="in-txt"><FaBuilding /> <input onChange={e => this.setState({ Company: e.target.value })} className="box-3" type="text" name="" placeholder="Company" /></div>
                              <div className="in-txt"><MdMessage /> <textarea onChange={e => this.setState({ message: e.target.value })} className="box-3" rows="4" placeholder="Message / Requirement"></textarea></div>
                              <Link style={{ width: '100%', textAlign: 'center' }} className="book_btn_form_button" onClick={() => this.submitForm()} to="#">Download</Link>
                            </form>
                          </div>
                        </div>
                      )
                      }
                    </Popup>
                  </div>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-5 col-md-3">
                  <img src={SEO_page_animated_image} alt="Loti File SEO Page" width="100%" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <AwardSec />

        <div className="SEO_welcome_sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className='page-title'>
                  <h1>Welcome to <span>iQuinceSoft</span></h1>
                  <p>The digital agency that specializes in Affordable SEO
                    services. Our team of experts has a proven track record of delivering results for
                    businesses of all sizes. Here are some key points to know about us:</p>
                  <ul>
                    <li><img src={check} alt="icon_check" />We are a full-service digital agency with a focus on SEO, helping businesses improve their online presence and drive more traffic to their websites.</li>
                    <li><img src={check} alt="icon_check" />Our team is made up of experienced professionals who stay up-to-date on the latest SEO trends and techniques to ensure our clients get the best possible results.</li>
                    <li><img src={check} alt="icon_check" />We have helped businesses across various industries improve their search engine rankings, resulting in more website traffic, leads, and sales.</li>
                    <li><img src={check} alt="icon_check" />At IquinceSoft, we believe that SEO is not just about getting more traffic, it's about getting the right traffic. We work closely with our clients to understand their target audience and develop a customized SEO strategy that will attract the right visitors to their website.</li>
                    <li><img src={check} alt="icon_check" />We understand that every business is unique, which is why we take a personalized approach to each project. We work closely with our clients to understand their goals, challenges, and budget, and develop a strategy that is tailored to their specific needs.</li>
                  </ul>
                  <p>If you're looking to improve your online presence and drive more traffic to your website, you've come to the right place. Contact us today to learn more about how our affordable SEO services can benefit your business.</p>
                </div>
              </div>
              <div className="col-lg-5 col-md-12" >
                <div className="SEO_form_sec">
                  <div className="container">
                    <div className="row" hidden>
                      <div className="col-lg-12 col-md-12">
                      </div>
                    </div>
                    <div className="row award-form-sec2">
                      <div className="col-lg-12">
                        <div className="Seo-sec">
                          <img alt="img" width="100%" src={Letstalk} />
                          <p><span>We’re actual humans here on the other side 👋</span></p>
                          <h1>Schedule a 15 minutes call to discuss your requirement</h1>


                          <PopupButton className="book-btn" type="button"
                            url="https://calendly.com/iquincesoft-sales/30min"
                            /*
                                            * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                                            * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                                            */
                            rootElement={document.getElementById("root")}
                            text-color="#00ccff"
                            text="Schedule an Appointment"
                          />

                          <p className="para-link"><Link to={'#EuquireSec'} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })} ><span>Or, Use this form to share your requirements.
                            Get guaranteed response within 8 Hrs.</span></Link></p>
                        </div>
                      </div>
                    </div>
                  </div >
                </div >
              </div>
            </div>
          </div>
        </div>


        <div className="SEO-sec">
          <div className="page-title">
            <h1>Services We <span>Offer </span></h1>
            <p> Boost Your Online Presence with an Affordable SEO Company: Our<br /> Services at a Fraction of the Cost</p>
          </div>
          <div className="row" >
            <div className="col-lg-3 col-md-6">
              <div className="SEO-txt-1">
                <h4><span><img src={Keyword_research} alt="Keyword_research" /></span><Link to="#" >Keyword Research and Analysis</Link></h4>
                <ul>
                  <li> <FaAngleDoubleRight /> Identify relevant and high-volume search terms for your business</li>
                  <li><FaAngleDoubleRight /> Optimize your website content for targeted keywords</li>
                  <li><FaAngleDoubleRight /> Improve your search engine rankings and attract more organic traffic</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="SEO-txt-1">
                <h4><span><img src={Page_Optimization} alt="On-Page Optimization" /></span><Link to="#" >On-Page Optimization</Link></h4>
                <ul>
                  <li> <FaAngleDoubleRight /> Optimize your website's structure, content, and HTML tags for search engines</li>
                  <li><FaAngleDoubleRight /> Improve user experience and website speed</li>
                  <li><FaAngleDoubleRight /> Boost your search engine rankings and attract more organic traffic</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="SEO-txt-1">
                <h4><span><img src={Off_Page_Optimization} alt="Off-Page Optimization" /></span><Link to="#" >Off-Page Optimization</Link></h4>
                <ul>
                  <li> <FaAngleDoubleRight /> Build high-quality backlinks from reputable websites</li>
                  <li><FaAngleDoubleRight /> Improve your website's authority and credibility</li>
                  <li><FaAngleDoubleRight /> Boost your search engine rankings and attract more organic traffic
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="SEO-txt-1">
                <h4><span><img src={Technical_Seo} alt="Technical_Seo" /></span><Link to="#" >Technical_Seo</Link></h4>
                <ul>
                  <li> <FaAngleDoubleRight /> Making a well-structured and crawlable website</li>
                  <li><FaAngleDoubleRight />  Removing negative impact by fixing technical issues</li>
                  <li><FaAngleDoubleRight /> Improving user experience to drive organic trafffic.</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="SEO-txt-1">
                <h4><span><img src={Local_Seo} alt="Local SEO" /></span><Link to="#" >Local SEO</Link></h4>
                <ul>
                  <li> <FaAngleDoubleRight /> Optimizing your website for local search queries</li>
                  <li><FaAngleDoubleRight /> Improving your visibility in local search results</li>
                  <li><FaAngleDoubleRight />Attracting more local customers to your business</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="SEO-txt-1">
                <h4><span><img src={Content_Marketing} alt="Content Marketing" /></span><Link to="#" >Content Marketing</Link></h4>
                <ul>
                  <li> <FaAngleDoubleRight /> Creating high-quality and engaging content for your website</li>
                  <li><FaAngleDoubleRight /> Attracting more visitors website and increase user engagement</li>
                  <li><FaAngleDoubleRight />Boosting your search engine rankings and attract more organic traffic</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="SEO-txt-1">
                <h4><span><img src={E_commerce_SEO} alt="E-commerce SEO" /></span><Link to="/hire-mern-stack-developers" >E-commerce SEO</Link></h4>
                <ul>
                  <li> <FaAngleDoubleRight />Optimizing product pages for improved online visibility</li>
                  <li><FaAngleDoubleRight /> Building backlinks to improve authority and drive traffic</li>
                  <li><FaAngleDoubleRight />Attaining better click through rate and product info with data markup</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="SEO-txt-1">
                <h4><span><img src={Global_seo} alt="International SEO " /></span><Link to="#" >International SEO </Link></h4>
                <ul>
                  <li> <FaAngleDoubleRight />Optimize website for international search engines & audiences</li>
                  <li><FaAngleDoubleRight /> Use hreflang tags to signal language & region of content </li>
                  <li><FaAngleDoubleRight />Conduct thorough keyword research for different regions
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-12 col-md-12'>
              <OwlCarousel options={servSlide}>
                <div className="SEO-txt-1">
                  <h4><span><img src={Off_Page_Optimization} alt="Off-Page Optimization" /></span><Link to="/hire-mern-stack-developers" >Off-Page Optimization</Link></h4>
                  <ul>
                    <li> <FaAngleDoubleRight /> Build high-quality backlinks from reputable websites</li>
                    <li><FaAngleDoubleRight /> Improve your website's authority and credibility</li>
                    <li><FaAngleDoubleRight /> Boost your search engine rankings and attract more organic traffic
                    </li>
                  </ul>
                </div>
                <div className="SEO-txt-1">
                  <h4><span><img src={Page_Optimization} alt="On-Page Optimization" /></span><Link to="#" >On-Page Optimization</Link></h4>
                  <ul>
                    <li> <FaAngleDoubleRight /> Optimize your website's structure, content, and HTML tags for search engines</li>
                    <li><FaAngleDoubleRight /> Improve user experience and website speed</li>
                    <li><FaAngleDoubleRight /> Boost your search engine rankings and attract more organic traffic</li>
                  </ul>
                </div>
                <div className="SEO-txt-1">
                  <h4><span><img src={Off_Page_Optimization} alt="Off-Page Optimization" /></span><Link to="#" >Off-Page Optimization</Link></h4>
                  <ul>
                    <li> <FaAngleDoubleRight /> Build high-quality backlinks from reputable websites</li>
                    <li><FaAngleDoubleRight /> Improve your website's authority and credibility</li>
                    <li><FaAngleDoubleRight /> Boost your search engine rankings and attract more organic traffic
                    </li>
                  </ul>
                </div>
                <div className="SEO-txt-1">
                  <h4><span><img src={Technical_Seo} alt="Technical_Seo" /></span><Link to="#" >Technical_Seo</Link></h4>
                  <ul>
                    <li> <FaAngleDoubleRight /> Making a well-structured and crawlable website</li>
                    <li><FaAngleDoubleRight />  Removing negative impact by fixing technical issues</li>
                    <li><FaAngleDoubleRight /> Improving user experience to drive organic trafffic.</li>
                  </ul>
                </div>
                <div className="SEO-txt-1">
                  <h4><span><img src={Local_Seo} alt="Local SEO" /></span><Link to="#" >Local SEO</Link></h4>
                  <ul>
                    <li> <FaAngleDoubleRight /> Optimizing your website for local search queries</li>
                    <li><FaAngleDoubleRight /> Improving your visibility in local search results</li>
                    <li><FaAngleDoubleRight />Attracting more local customers to your business</li>
                  </ul>
                </div>
                <div className="SEO-txt-1">
                  <h4><span><img src={Content_Marketing} alt="Content Marketing" /></span><Link to="#" >Content Marketing</Link></h4>
                  <ul>
                    <li> <FaAngleDoubleRight /> Creating high-quality and engaging content for your website</li>
                    <li><FaAngleDoubleRight /> Attracting more visitors website and increase user engagement</li>
                    <li><FaAngleDoubleRight />Boosting your search engine rankings and attract more organic traffic</li>
                  </ul>
                </div>
              </OwlCarousel>
            </div>
          </div>

          <div className='container'>
            <div className='row'>
              <div className='col-lg-1 col-md-1'></div>
              <div className='col-lg-9 col-md-9 col-sm-12'>
                <div className='page-title-2'>
                  <p>Each of these SEO services can have a significant impact on your website's search engine
                    rankings and overall online presence. By implementing these services, we can attract more
                    organic traffic, improve your website's user experience, and ultimately drive more leads and
                    sales to your business. Contact us to learn more about how our  affordable SEO services can
                    benefit your business.</p>
                  <Popup className='plan-popup' trigger={<button className="book-btn" onClick={() => handleClick()} type="button">Enquire Now</button>}
                    modal >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>&times; </button>
                        <div className="content">
                          <h3>Download SEO <span>Package</span></h3>
                          <form>
                            <div className="in-txt"><FaUser /> <input onChange={e => this.setState({ name: e.target.value })} className="box-3" type="text" name="" placeholder="Name" /></div>
                            <div className="in-txt"><FaEnvelope /> <input onChange={e => this.setState({ email: e.target.value })} className="box-3" type="email" name="" placeholder="Work e-Mail" /></div>
                            <div className="in-txt"><FaPhoneAlt /> <input onChange={e => this.setState({ number: e.target.value })} className="box-3" type="number" name="" placeholder="Phone Number" /></div>
                            <div className="in-txt"><FaBuilding /> <input onChange={e => this.setState({ Company: e.target.value })} className="box-3" type="text" name="" placeholder="Company" /></div>
                            <div className="in-txt"><MdMessage /> <textarea onChange={e => this.setState({ message: e.target.value })} className="box-3" rows="4" placeholder="Message / Requirement"></textarea></div>
                            <Link style={{ width: '100%', textAlign: 'center' }} className="book_btn_form_button" onClick={() => this.submitForm()} to="#">Download</Link>
                          </form>
                        </div>
                      </div>
                    )
                    }
                  </Popup>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='SEO-plan'>
          <div className="page-title">
            <h1>Explore Our Seo <span>Packages, Plans And Pricing</span></h1>
            <p> Generate leads online that works for your business</p>
          </div>
          <div className='container'>
            <div className='row'>

              <div className='col-lg-3 col-md-4'>
                <div className='SEO-plan-sec'>
                  <div className='SEO-plan-sec-1'>
                    <h1>Basic Boost</h1>
                  </div>
                  <div className='SEO-plan-sec-2'>
                    <h1><span>Keywords</span><br />Up to 10</h1>
                    <h1><span>Page Optimization</span><br />20 Web Page (Max)</h1>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-4'>
                <div className='SEO-plan-sec'>
                  <div className='SEO-plan-sec-1'>
                    <h1>Enhanced Edge</h1>
                  </div>
                  <div className='SEO-plan-sec-2'>
                    <h1><span>Keywords</span><br />Up to 20</h1>
                    <h1><span>Page Optimization</span><br />30 Web Page (Max)</h1>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-4'>
                <div className='SEO-plan-sec'>
                  <div className='SEO-plan-sec-1'>
                    <h1>Premium Pulse</h1>
                  </div>
                  <div className='SEO-plan-sec-2'>
                    <h1><span>Keywords</span><br />Up to 40</h1>
                    <h1><span>Page Optimization</span><br />50 Web Page (Max)</h1>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-4'>
                <div className='SEO-plan-sec'>
                  <div className='SEO-plan-sec-1'>
                    <h1>Ultimate Upshot</h1>
                  </div>
                  <div className='SEO-plan-sec-2'>
                    <h1><span>Keywords</span><br />Up to 100</h1>
                    <h1><span>Page Optimization</span><br />100 Web Page (Max)</h1>
                  </div>
                </div>
              </div>
              <div className='col-lg-12 col-md-12'>
                <OwlCarousel options={servSlide}>
                  <div className='SEO-plan-sec'>
                    <div className='SEO-plan-sec-1'>
                      <h1>Basic Boost</h1>
                    </div>
                    <div className='SEO-plan-sec-2'>
                      <h1><span>Keywords</span><br />Up to 10</h1>
                      <h1><span>Page Optimization</span><br />20 Web Page (Max)</h1>
                    </div>
                  </div>
                  <div className='SEO-plan-sec'>
                    <div className='SEO-plan-sec-1'>
                      <h1>Enhanced Edge</h1>
                    </div>
                    <div className='SEO-plan-sec-2'>
                      <h1><span>Keywords</span><br />Up to 20</h1>
                      <h1><span>Page Optimization</span><br />30 Web Page (Max)</h1>
                    </div>
                  </div>
                  <div className='SEO-plan-sec'>
                    <div className='SEO-plan-sec-1'>
                      <h1>Ultimate Upshot</h1>
                    </div>
                    <div className='SEO-plan-sec-2'>
                      <h1><span>Keywords</span><br />Up to 100</h1>
                      <h1><span>Page Optimization</span><br />100 Web Page (Max)</h1>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
            <Popup className='plan-popup' trigger={<button className="book-btn" onClick={() => handleClick()} type="button">View More</button>}
              modal >
              {close => (
                <div className="modal">
                  <button className="close" onClick={close}>&times; </button>
                  <div className="content">
                    <h3>Download SEO <span>Package</span></h3>
                    <form>
                      <div className="in-txt"><FaUser /> <input onChange={e => this.setState({ name: e.target.value })} className="box-3" type="text" name="" placeholder="Name" /></div>
                      <div className="in-txt"><FaEnvelope /> <input onChange={e => this.setState({ email: e.target.value })} className="box-3" type="email" name="" placeholder="Work e-Mail" /></div>
                      <div className="in-txt"><FaPhoneAlt /> <input onChange={e => this.setState({ number: e.target.value })} className="box-3" type="number" name="" placeholder="Phone Number" /></div>
                      <div className="in-txt"><FaBuilding /> <input onChange={e => this.setState({ Company: e.target.value })} className="box-3" type="text" name="" placeholder="Company" /></div>
                      <div className="in-txt"><MdMessage /> <textarea onChange={e => this.setState({ message: e.target.value })} className="box-3" rows="4" placeholder="Message / Requirement"></textarea></div>
                      <Link style={{ width: '100%', textAlign: 'center' }} className="book_btn_form_button" onClick={() => this.submitForm()} to="#">Download</Link>
                    </form>
                  </div>
                </div>
              )
              }
            </Popup>
          </div>

        </div>

        <div className='Our-SEO-Process'>
          <div className="page-title">
            <h1>Our <span>Process</span></h1>
            <p> Our cost-effective SEO packages encompass a set of procedures that we diligently adhere to.</p>
          </div>

          <div className='container'>
            <div className='row'>
              <div className='col-lg-1'></div>
              <div className='col-lg-10 col-md-12'>
                <Tabs>
                  <TabList >
                    <Tab><img src={Analysis} alt='Analysis' />Analysis</Tab>
                    <Tab><img src={Strategy} alt='Strategy' />Strategy</Tab>
                    <Tab><img src={Implementation} alt='Implementation' />Implementation</Tab>
                    <Tab><img src={Dollar} alt='Results, Rinse, Repeat' />Results, Rinse, Repeat</Tab>
                  </TabList>

                  <TabPanel>
                    <div className='row'>
                      <div className='col-lg-6 col-md-6'>
                        <div className='Our-SEO-Process-sec'>
                          <p>To enhance our clients' online presence through affordable SEO services,
                            we start by conducting website audits, competitor research, and keyword analysis.
                            These   analyses   help   us   identify   areas   for   improvement,   gain   insights   into
                            competitors, and establish a baseline for future progress.</p>
                        </div>
                      </div>
                      <div className='col-lg-1'></div>
                      <div className='col-lg-5 col-md-6'>
                        <img src={analysis_digital_marketing} width="100%" alt="analysis-digital-marketing" />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className='row'>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='Our-SEO-Process-sec'>
                          <p>An  affordable   search   engine   optimization  strategy is created based on analysis, outlining goals, tactics, and timelines for desired results. Benefits include clear direction, alignment with business objectives, and a roadmap for success. SEO services include consulting, content planning, and link building.</p>
                        </div>
                      </div>
                      <div className='col-lg-1'></div>
                      <div className='col-lg-5 col-md-6'>
                        <img src={strategy_planning} width="100%" alt="analysis-digital-marketing" />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className='row'>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='Our-SEO-Process-sec'>
                          <p>This is the phase where the SEO strategy is put into action, such as optimizing website content, building links, and monitoring progress. The benefits of implementation include executing the plan, improving the website's search engine visibility, and increasing website traffic. SEO services related to implementation include on-page optimization, link building, and content creation.</p>
                        </div>
                      </div>
                      <div className='col-lg-1'></div>
                      <div className='col-lg-5 col-md-6'>
                        <img src={employee_implementing_business_idea} width="100%" alt="analysis-digital-marketing" />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className='row'>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='Our-SEO-Process-sec'>
                          <p>In this final step, the results of the implementation are measured and analyzed, and adjustments are made as necessary to optimize performance continually. The benefits of this step include tracking progress towards goals, identifying areas for improvement, and refining the SEO strategy. SEO services related to results include analytics reporting, performance tracking, and ongoing SEO maintenance.</p>
                        </div>
                      </div>
                      <div className='col-lg-1'></div>
                      <div className='col-lg-5 col-md-6'>
                        <img src={seo_Plan} width="100%" alt="analysis-digital-marketing" />
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>

              </div>
            </div>
          </div>
          <ReviewSec />

          <div className='ParallelX_image'>
            <div className="container">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 col-md-6 col-sm-12">
                  <div className="SEO-Maximize-sec">
                    <p>Maximize your website's potential with affordable SEO services from iquincesoft. Don't miss out on the opportunity to increase your online visibility and attract more customers.</p>
                    <Link className="book-btn" type="button" to={"/contact-us"}>ContactUs</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="frequent-sec">
          <h3>Frequently Asked Questions</h3>
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-1"></div>
              <div className="col-lg-8 col-md-10" >
                <Accordion allowZeroExpanded preExpanded={['a']}>
                  <AccordionItem>
                    <AccordionItemButton>
                      What kind of results can I expect from SEO services?
                    </AccordionItemButton>
                    <AccordionItemPanel>
                      SEO is a long-term strategy that takes time to show results. The specific results that a website can expect will depend on a variety of factors, such as the competition in the industry, the website's current state, and the SEO strategy being implemented. However, typical results include increased website traffic, higher search engine rankings, and improved website visibility.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemButton>
                      How long does it take to see results from SEO services?
                    </AccordionItemButton>
                    <AccordionItemPanel>
                      SEO is a long-term strategy, and results can take anywhere from a few months to a year or more to fully manifest. However, some improvements can be seen in the first few months of the campaign. It's important to note that SEO is an ongoing process, and results may vary depending on the specific goals and needs of each website.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemButton>
                      How much do SEO services cost?
                    </AccordionItemButton>
                    <AccordionItemPanel>
                      The cost of SEO services can vary depending on the scope of the project, the level of competition in the industry, and the specific needs and goals of the website. Some SEO companies may charge a monthly retainer fee, while others may charge a flat fee for a specific project or campaign. It's important to get a clear understanding of the costs involved before signing up for any SEO services.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemButton>
                      What kind of SEO techniques do you use?
                    </AccordionItemButton>
                    <AccordionItemPanel>
                      SEO techniques can vary depending on the specific needs and goals of each website, as well as the current state of the website's SEO. Some common SEO techniques include keyword research and optimization, on-page optimization, link building, technical SEO, and content marketing. We use a variety of SEO techniques to help our clients achieve their goals, and we customize our approach based on the specific needs of each client.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemButton>
                      How do you measure the success of your SEO campaigns?
                    </AccordionItemButton>
                    <AccordionItemPanel>
                      We measure the success of our SEO campaigns by tracking key metrics such as website traffic, search engine rankings, and conversion rates. We use analytics tools such as Google Analytics and Ahrefs to monitor progress and make data-driven decisions about our SEO strategy. We also provide regular reports to our clients to keep them informed about the progress of their SEO campaigns.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemButton>
                      Will I be able to see the work that you're doing?
                    </AccordionItemButton>
                    <AccordionItemPanel>
                      We believe in transparency and communication with our clients, and we provide regular updates on the work that we're doing. We may also provide access to tools such as Google Analytics and Google Search Console so that clients can see the progress of their campaigns in real-time. We are always available to answer any questions that clients may have about the work that we're doing.
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>

              </div>
            </div>
          </div>
        </div>


        <div className="culture-sec insight-sec">
          <h3>Featured Insights</h3>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                {CommerceJson.length && (
                  <OwlCarousel options={culture}>
                    {CommerceJson.map(CommerceJsonS => {
                      return (
                        <div className="cult-box">
                          <div className="tech-dot"></div>
                          <h4>{CommerceJsonS.name}</h4>
                          <p dangerouslySetInnerHTML={{ __html: CommerceJsonS.content.substr(0, 100) }}></p>
                          <Link to={`/PostPage/${CommerceJsonS.slug}`} >READ MORE </Link>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                )}

                <div className="row">
                  {CommerceJson && CommerceJson.map((CommerceJsonS, index) => {
                    return (
                      <div className="col-lg-4 col-md-4">
                        <div className="insight-box" key={index}>
                          <h4>{CommerceJsonS.name}</h4>
                          <p dangerouslySetInnerHTML={{ __html: CommerceJsonS.content.substr(0, 100) }}></p>
                          <Link to={`/PostPage/${CommerceJsonS.slug}`} >READ MORE </Link>
                        </div>
                      </div>

                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <FormPopup />
        </div>

        <Footer />
      </>
    );
  }
}
export default SEOPage;