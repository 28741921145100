import React, { Component } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import talk from '../images/talk.webp';
import 'reactjs-popup/dist/index.css';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import { PopupButton } from "react-calendly";

class AwardFormsec extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            number: "",
            value: new Date()

        }
    }

    onChange = date => this.setState({ date })
    submitForm = e => {
        if (this.state.name === '' || this.state.email === '' || this.state.number === '') {
            alert("some field are missing filled it");
        }
        else {
            let formData = new FormData()
            formData.set("your-name", this.state.name)
            formData.set("your-email", this.state.email)
            formData.set("your-number", this.state.number)
            axios
                .post(
                    'https://dev.iquincesoft.com/iqsandbox/wp-json/contact-form-7/v1/contact-forms/1674/feedback',
                    formData,
                    {
                        headers: {
                            "content-type": "multipart/form-data",
                        },
                    }
                )
                .then(res => {
                    res.data.status === "mail_sent"
                        ? this.setState({
                            name: "",
                            email: "",
                            number: "",

                        })
                        : this.setState({ errorMessage: res.data.message }, () => {
                            setTimeout(() => {
                                this.setState({ errorMessage: "" })
                            }, 2000)
                        })
                })
            alert("thank you for submission");
        }
    }
    render() {
        return (
            <div className="award-form-sec">
                <div className="container">
                    <div className="row" hidden>
                        <div className="col-lg-12 col-md-12">
                        </div>
                    </div>
                    <div className="row award-form-sec2">
                        <div className="col-lg-12">
                            <div className="form-sec">
                                <img alt="img" src={talk} />
                                <p><span>We’re actual humans here on the other side 👋</span></p>
                                <h1>Schedule a 15 minutes call to discuss your requirement</h1>


                                <PopupButton className="book-btn" type="button"
                                    url="https://calendly.com/iquincesoft-sales/30min"
                                    /*
                                                    * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                                                    * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                                                    */
                                    rootElement={document.getElementById("root")}
                                    text-color="#00ccff"
                                    text="Schedule an Appointment"
                                />

                                <p className="para-link"><Link to={'#EuquireSec'} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })} ><span>Or, Use this form to share your requirements.
                                    Get guaranteed response within 8 Hrs.</span></Link></p>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}
export default AwardFormsec;