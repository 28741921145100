import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import img1 from '../images/Executive page images/Lakhendra-singh.webp';

export default class ExecutiveDetails extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="Executive-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <img alt="img" width="100%" src={img1} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="Executive-details-section">
                                    <h1>Lakhendra Singh</h1>
                                    <h2>Chairman and Chief Executive Officer</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="executive-details-content">
                                    <p>Lakhendra Singh is the chairman and chief executive officer of iQuinceSoft, a leading IT solutions provider and consulting company. In 2012, Lakhendra started iQuinceSoft Consulting Private Limited with a vision to make Mathura – one of the most primitive and religiously & culturally towns of prime significance, as the Job Market for the educated youth of the surrounding areas.</p>
                                    <p>Lakhendra has worked with numerous American and European clients by offering them scalable and robust software, mobile, and web solutions with renowned business consulting to his credit. Under his leadership, iQuinceSoft has delivered seamless experiences for the customers, including different styles and tones of content, web & app solutions, and software solutions, including multiple SaaS products. iQuinceSoft offers optimal business opportunities to businesses. </p>
                                    <p>Before setting up this solution-based firm in Mathura, Lakhendra has served for four years as Business Analyst at FranConnect. At iQuinceSoft Consulting, Lakhendra is the Key Opinion Leader in making crucial decisions for the organization. Throughout his journey from working for an American multinational to working with American Multinationals, he has enriched his thought process by becoming an avid-reader. In the business fraternities of different verticals, he is considered as an esteemed KOL. </p>
                                    <p>iQuinceSoft has delivered more than 5000 projects to clients successfully. The organization is driven by passionate people, skilled and vibrant talents, and tech-savvy youth to deliver delightful outcomes under the dynamic leadership of Lakhendra. iQuinceSoft has earned tokens of respect and love across the globe for tangible products, time-bound deliveries, and satisfying results with ‘call-us-anytime’ support.</p>
                                    <h2>Corporate Social Responsibility</h2>
                                    <p>Connected with the roots, Mr. Singh has planted more than 1100 trees in the last ten years. He drives a bicycle to keep himself fit and for less carbon emission. He believes that we all need to come along to make this world a better place to live with more smiles, happiness, and inclusion across diverse cultures, nations, and people, making society resilient.</p>
                                    <h2>Personal and educational background</h2>
                                    <p>Lakhendra is a post-graduate in business administration from the International Institute of Information Technology, Pune, India. Lakhendra has helped societies become resilient, more profound, and resourceful.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}
