
import React, { Component } from 'react';
import FormPopup from '../container/FormPopup';
import { HashLink as Link } from 'react-router-hash-link';

// import easy from '../images/easy-icon.svg';
// import easy1 from '../images/easy-icon1.svg';

class EasySec extends Component {
	render() {

		return (
			<>
				<div className="easy-sec">
					<h3>Ease of selection – Variable Pricing to fit your Budget & Business</h3>
					<div className="container">
						<div className="row">
							<div className="col-lg-4 col-md-4" >
								<div className="easy-box">
									{/* <img alt="img" src={easy} /> */}
									<h4><span>Junior Level</span></h4>
									<center><h4><hr style={{ width: '50%' }} /></h4></center>
									<h6><span>$1500 - $2000 </span></h6>
									<center><h4><hr style={{ width: '50%' }} /></h4></center>
									<p>Resources with the average experience, with the majority of necessary skill sets. They are technical, sound, quick and task driven. They aren’t fresher and trainee level talents but simply have less exposure as compared to mid and senior level. </p>
								</div>
							</div>
							<div className="col-lg-4 col-md-4" >
								<div className="easy-box">
									{/* <img alt="img" src={easy1} /> */}
									<h4><span>Mid - Level</span></h4>
									<center><h4><hr style={{ width: '50%' }} /></h4></center>
									<h6><span>$2000 - $3500</span></h6>
									<center><h4><hr style={{ width: '50%' }} /></h4></center>
									<p>Resources with a good level of experience, they are deeply technical, suggestive, problem solver, process – driven.</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-4" >
								<div className="easy-box">
									{/* <img alt="img" src={easy1} /> */}
									<h4><span>Senior Level</span></h4>
									<center><h4><hr style={{ width: '50%' }} /></h4></center>
									<h6><span>$3500 - $4500</span></h6>
									<center><h4><hr style={{ width: '50%' }} /></h4></center>
									<p>Resources who have exceptionally strong skills, expertise and experience. They are highly competitive, technical specialists, innovative, solution finder, outcome oriented.</p>
								</div>
							</div>
						</div>
					</div>
					<FormPopup />
					<Link to={"#EuquireSec"} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}><h5>Or, Use this form to share your requirements. <span>Get guaranteed response within 8 Hrs.</span></h5></Link>
				</div>
				{/* <div className="easy-sec">
					<h3>Ease of selection – Variable Pricing to fit your Budget & Business</h3>
					<div className="container">
						<center><p className="ease-sec-peragraph"><span>We ensure you get what you need. Hire a dedicated web developer who’ll work as per your instruction for 8Hr/day and 160 hours per month with a monthly rolling contract.
							Save 25% over fixed pricing projects.</span></p	></center>
						<div className="row">
							<div className="col-lg-4 col-md-4" >
								<div className="easy-box">
									<h4><span>Juniour Level</span></h4>
									<spacer />
									<p>represents the talents with the average experience, with the majority of necessary skill sets. They are technical, sound, quick and task driven. They aren’t fresher and trainee level talents but simply have less exposure as compared to mid and senior level.
									</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-4" >
								<div className="easy-box">
									<h4><span>Mid - Level</span></h4>
									<p>represent the talents with a good level of experience, they are deeply technical, suggestive, problem solver, process – driven.</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-4" >
								<div className="easy-box">
									<h4><span>Senior Level</span></h4>
									<p>represent the talents who have exceptionally strong skills, expertise and experience. They are highly competitive, technical specialists, innovative, solution finder, outcome oriented.</p>
								</div>
							</div>
						</div>
					</div>
					<FormPopup />
					<h5><Link to={'#EuquireSec'} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>Or, Use this form to share your requirements.<span>Get guaranteed response within 8 Hrs.</span></Link></h5>
				</div> */}
			</>
		);
	}
}

export default EasySec;