import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import { CaseStudies } from '../json/CaseStudies';

export default class ExecutiveDetailsfulldetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            teams: [],
            url: this.props.match.params.post
        }
    }
    componentDidMount() {
        CaseStudies.iQuincesoftLeadsDetails(this.state.url).then((data, err) => {
            if (!err) {
                this.setState({
                    teams: data,
                });
            }
        });
    }
    render() {
        const { teams } = this.state;
        return (
            <>
                <Header />
                {teams.map((data) => (
                    <div className="Executive-details" key={data.id}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <img alt="img" width="100%" src={data.details} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="Executive-details-section">
                                        <h1>{data.name}</h1>
                                        <h2 dangerouslySetInnerHTML={{ __html: data.profile }}></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="executive-details-content">
                                        <p dangerouslySetInnerHTML={{ __html: data.content }}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                )}
                <Footer />
            </>
        );
    }
}
